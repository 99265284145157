import NotFoundHeader from 'components/NotFoundHeader';
import NotFoundSVG from 'components/SVG/NotFoundSVG';
import React, { useEffect } from 'react';
import SEO from 'components/SEO';
import { changeColorOnVisible } from 'hooks/changeColorOnScroll';
import { useDispatch } from 'react-redux';

const NotFound = () => {
  const disptach = useDispatch();

  useEffect(() => {
    changeColorOnVisible('#ffffff', true, disptach);
  });

  return (
    <>
      <SEO title="Not Found page" description="la page d'acceuil du site" />
      <NotFoundHeader />
      <NotFoundSVG className="not-found-svg z-index-2" />
    </>
  );
};

export default NotFound;
